<template>
  <v-app>
    <v-main>
      <navbar :showSite="showSite" @this-site="thisSite" :items="adresser" />
      <detaljer :showSite="showSite" @this-site="thisSite" />
      <home :showSite="showSite" @this-site="thisSite" />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar';
import detaljer from '@/components/Detaljer';
import home from '@/components/Home';
import Sitedata from '@/data/Sites.json';

export default {
  name: 'App',
  components: {
    Navbar,
    home,
    detaljer,
  },
  data() {
    
    return {
      showSite: '',
      adresser: '',
    }
  },
  created() {
    const adresser = Sitedata.features.map(adder => (adder.properties.adresse))
    //console.log(adresser)
  },
  methods: {
    thisSite(nr) {
      //console.log("thisSite nr:" + nr)
      this.showSite = Sitedata.features.filter((item) => item.properties.siteid === nr);
      //console.log("this:" + this.showSite)
    }
  }
}
</script>