<template>
  <div class="home">
    <l-map
      :center="[55.6735790, 12.7404520]"
      v-model="zoom"
      v-model:zoom="zoom"
      :options="mapOptions"
      >
      <l-control-layers
        :position="layersPosition"
        :collapsed="false"
      />
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        :attribution="attribution">
      </l-tile-layer>
      <l-layer-group
        v-for="item in Bstuff"
        :key="item.id"
        layer-type="overlay"
        name="Bonus"
      >
        <l-marker v-for="marker in Bonus"
          :key="marker.properties.siteid"
          :lat-lng="marker.geometry.coordinates"
          :icon="iconbonus"
          :visible="true"
          @click="markerno(marker.properties.siteid)">
          <l-tooltip>
            <strong>Lokations id: </strong>{{marker.properties.siteid}}<br>
            {{marker.properties.adresse}}<br>
            {{marker.markertype}}
          </l-tooltip>
        </l-marker>
      </l-layer-group>
      <l-layer-group
        v-for="item in Ustuff"
        :key="item.id"
        layer-type="overlay"
        name="Uno-X"
      >
        <l-marker v-for="marker in UnoX"
          :key="marker.properties.siteid"
          :lat-lng="marker.geometry.coordinates"
          :icon="iconuno"
          :visible="true"
          @click="markerno(marker.properties.siteid)">
          >
          <l-tooltip>
            <strong>Lokations id: </strong>{{marker.properties.siteid}}<br>
            {{marker.properties.adresse}}<br>
            {{marker.markertype}}
          </l-tooltip>
        </l-marker>
      </l-layer-group>
    </l-map>
  </div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LLayerGroup,
  LControlLayers,
  /*
  LPopup,
  LIcon,
  LTooltip,
  LPolyline,
  LPolygon,
  LRectangle,
  */
} from '@vue-leaflet/vue-leaflet';
import { ref } from 'vue';
import { icon } from 'leaflet';
import detaljer from './Detaljer.vue';
import Sitedata from '../data/Sites.json';
import Navbar from './Navbar.vue';
import { jSXExpressionContainer } from '@babel/types';

export default {
  name: 'Home',
  props: { showSite: Object },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LLayerGroup,
    LControlLayers,
    detaljer,
    Navbar,
},
  data() {
    return {
    }
  },
  setup() {
    const zoom = ref(8);
    const iconuno = require('../assets/fuel_unox.png'); // eslint-disable-line global-require
    const iconbonus = require('../assets/fuel_bonus.png'); // eslint-disable-line global-require
    const Bonus = Sitedata.features.filter((item) => item.markertype === 'Bonus');
    const UnoX = Sitedata.features.filter((item) => item.markertype === 'Uno-X Automat');
    return {
      zoom,
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      iconuno: icon({
        iconUrl: iconuno,
        iconSize: [25, 25],
        iconAnchor: [12, 25],
      }),
      iconbonus: icon({
        iconUrl: iconbonus,
        iconSize: [25, 25],
        iconAnchor: [12, 25],
      }),
      mapOptions: {
        zoomSnap: 0,
        zoomDelta: 0.5,
        // minZoom: 4,
        // maxZoom: 12,
        // wheelDebounceTime: 100,
      },
      layersPosition: 'topright',
      Bonus,
      UnoX,
      Bstuff: [
        {
          id: 'b1',
          markers: Bonus,
          visible: true,
          markersVisible: true,
        },
      ],
      Ustuff: [
        {
          id: 'u1',
          markers: UnoX,
          visible: true,
          markersVisible: true,
        },
      ],
    };
  },
  computed: {
  },
  methods: {
    log(a) {
      console.log(a);
    },
    markerno(nr) {
      this.$emit('this-site',nr)
    }
  },

  zoomUpdate(zoom) {
    this.currentZoom = zoom;
  },
  centerUpdate(center) {
    this.currentCenter = center;
  },
};

</script>

<style lang="scss">
.home {
  height: 900px;
  width: 75%;
}
</style>
