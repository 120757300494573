<template>
<div class="detaljer">
  <v-col cols="12">
    <v-autocomplete
      v-model="adr"
      :items="items"
      dense
      single-line
      flat
      label="Søg Adresse"
    ></v-autocomplete>
  </v-col>
  <div class="thissite" v-if="this.showSite">
    <img :src="require('../assets/'+ this.showSite[0].properties.image)" width="350">
    <p>Lokations id: {{ this.showSite[0].properties.siteid }}</p>
    <p>{{ this.showSite[0].properties.beskrivelse }}</p>
    <p>{{ this.showSite[0].properties.adresse }}</p>
    <p>Distriktschef: {{ this.showSite[0].properties.tilsyn }}</p>
    <h2>Forplads</h2>
    <p>Standere: {{ this.showSite[0].standerantal }}</p>
    <p>Tanke: {{ this.showSite[0].tankantal }}</p>
    <div v-if="this.showSite[0].vaskehal"><p>Vaskehal: Ja</p></div>
    <div v-else><p>Vaskehal: Nej</p></div>
    <br>
    <a class="alink" :href="`https://mtsites.balle-larsen.dk/share/page/site/Tank-lokationer/documentlibrary#filter=path%7C%2FLokation${this.showSite[0].properties.siteid}`"
      target='_blank'>Vis Detaljer</a>
  </div>    
</div>
</template>

<script>
import VueBasicAlert from 'vue-basic-alert';
import Sitedata from '../data/Sites.json';

export default {
  name: 'detaljer',
  props: { showSite: Object },
  components: {
    VueBasicAlert,
  },
  created() {
    this.alleAdresser = Sitedata.features.filter((item) => item.properties.adresse);
  },
  computed: {
    filteredAdresser: function() {
      return this.alleAdresser.filter((Adre) => {
        return Adre.properties.adresse.match(this.search)
      });
    }
  },
  data() {
    const Bonus = Sitedata.features.filter((item) => item.markertype === 'Bonus');
    const UnoX = Sitedata.features.filter((item) => item.markertype === 'Uno-X Automat');
    const myInput = '';
    const length = 0;
    const message = '';
    const url = 'https://alfie.balle-larsen.dk/#/favorite/libraries/055ed706-854e-48dc-8c36-d460264eaf79';
    const address = Sitedata.features.map(adder => ( adder.properties.adresse));
    return {
      Sitedata,
      Bonus,
      UnoX,
      myInput,
      length,
      message,
      dialog: false,
      adresse: '',
      search: '',
      items: address,
      adr: '',
    };
  },
  watch: {
     adr(value) {
      let nummer = "";
      let denne = "";
      denne = Sitedata.features.filter((item) => item.properties.adresse === value);
      nummer = denne[0].properties.siteid;
      this.$emit('this-site',nummer);
    }
  }
};
</script>

methods: {
    getLength() {
      return this.myInput;
    },
  Skrivsti(siteNo, element) {
    // siteNo = "Tank" + site.siteid
    siteNo = "Tank" + "1"
    // element = "Forplads1"
    if (element !== "Forplads1") element = element.udskil
      // console.log(element)
    return "https://mtsites.balle-larsen.dk/share/page/site/Tank-lokationer/documentlibrary#filter=path%7C%2FLokation"
    + siteNo + "%2F" + element
  },
  redirect() {
    var url = "https://mtsites.balle-larsen.dk/share/page/site/Tank-lokationer/documentlibrary#filter=path%7C%2F1%2FForplads1";
    this.$router.push({ path: "/dashboard" });
    window.open(url, "_target");
  },
  detaljerne(event) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic Og==");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Methods", "GET, POST, PATCH, PUT, DELETE, OPTIONS");
      myHeaders.append("Access-Control-Allow-Headers", "Origin, Content-Type, X-Auth-Token");
      var raw = JSON.stringify({
        "username": "finn",
        "password": "krummelur"
      });
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("`https://alfie.balle-larsen.dk:80/share/page/site/Tank-lokationer/documentlibrary#filter=path%7C%2FLokation${this.showSite[0].properties.siteid}`", requestOptions)
      .then(response => response.text())
      .then(result => console.log('result', result))
      .catch(error => console.log('error', error));
    },
    logpaa(event) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic Og==");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Methods", "GET, POST, PATCH, PUT, DELETE, OPTIONS");
      myHeaders.append("Access-Control-Allow-Headers", "Origin, Content-Type, X-Auth-Token");
      var raw = JSON.stringify({
        "username": "finn",
        "password": "krummelur"
      });
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("http://localhost:3000/alfresco/s/api/login?host=alfie.balle-larsen.dk&port=80", requestOptions)
      .then(response => response.text())
      .then(result => console.log('result', result))
      .catch(error => console.log('error', error));
    },
    rettelse(event) {
      alert("rettelse")
    },
    adr (){
          return this.adresse = adress[0].properties.adresse
        },
    reset () {
      this.adresse = "Ukendt"
    }
  },

<style>
div.detaljer {
  position: absolute;
  right: 0;
  padding-top: 56px;
  height: 900px;
  width: 25%;
  background: rgb(238, 238, 238);
}
div.thissite {
  text-align: left;
  margin-left: 5px;
}

a.alink:link, a.alink:visited {
  background-color: rgb(238, 238, 238);
  color: black;
  border: 2px solid blue;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

a.alink:hover, a.alink:active {
  background-color: blue;
  color: white;
}
a.abtn, a.btn:visited {
  background-color: rgb(238, 238, 238);
  color: black;
  border: 2px solid blue;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

a.btn:hover, a.btn:active {
  background-color: blue;
  color: white;
}
p {
  font-size: 15px;
  line-height: 20px;
}
input[type="text"] {
  border-radius: 0;
}
input {
  /* 1 */
  overflow: visible;
}
</style>
