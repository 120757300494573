<template v-if="showSite">
  <div class="navbar">
    <nav>
      <v-app-bar
      color="rgb(238, 238, 238)"
      shrink-on-scroll
      flat
      scroll-target="#scrolling-techniques-4"
      >
      <v-toolbar-title>MTSites</v-toolbar-title>               
      <v-spacer></v-spacer>
      <v-btn
          color="blue"
          dark
          v-bind="$attrs"
          @click="dialog = true"
          >
          <span>REDIGER</span>
      </v-btn>
    </v-app-bar>
    </nav>
  </div>
    <v-dialog
    v-model="dialog"
    persistent
    >
   <v-card>
    <v-card-title>
      <span class="text-h5">Lokationens Stamdata</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <span>Siteid: {{ showSite[0].properties.siteid }}</span><p></p>
          <v-col cols="12">
            <v-text-field
              label="Distriktschef"
              required
            >
          </v-text-field>
          </v-col>
            <div class="thisshowsite" v-if="!!this.showSite">
            </div>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Telefonnummer"
              required
            >
          </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Adresse"
              required
            >
          </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" >
            <v-select
              :items="['','Sjælland', 'Fyn', 'Jylland']"
              label="Region"
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" >
            <v-autocomplete
              :items="['Uno-X', 'Bonus']"
              label="Selskab*"
              required
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      <small>*kræver feltet udfyldes</small>
      </v-card-text>
      <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        @click="dialog = false"
      >
        Luk
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="dialog = false"
        >
        Gem
      </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Sitedata from '@/data/Sites.json';
  export default {
    name:'Navbar',
    props: {
      showSite: Object
    },
    methods: {
      findnummer(event) {
        let dette = '000';
        this.event = event;
        dette = Sitedata.features.filter((item) => item.properties.adresse === event.target.value);
        //console.log("i findnummer" + event.target.value)
        this.$emit('this-site',dette[0].properties.siteid)
        //alert(event.target.value)
      },
      setstate(state) {
        let dette = '000';
        this.state = '';
        dette = Sitedata.features.filter((item) => item.properties.adresse === this.state);
        //this.findnummer(dette);
      },
    },  
    data() {
      const address = Sitedata.features.map(adder => ( adder.properties.adresse))
      return {
        dialog: false,
        searchClosed: true,
        search: '',
        items: address,
        itemHeight: 39,
        selectedItem: null,
        selected: 0,
        visible: false
      };
    }
  }
</script>

<style scoped>
.autocomplete {
    width: 50%;
    position: relative;
}
.input {
    height: 40px;
    border-radius: 3px;
    border: 2px solid lightgray;
    box-shadow: 0 0 10px #eceaea;
    font-size: 25px;
    padding-left: 10px;
    padding-top: 10px;
    cursor: text;
}
.close {
    position: absolute;
    right: 2px;
    top: 4px;
    background: none;
    border: none;
    font-size: 30px;
    color: lightgrey;
    cursor: pointer;
}
.placeholder {
    position: absolute;
    top: 11px;
    left: 11px;
    font-size: 25px;
    color: #d0d0d0;
    pointer-events: none;
}
.popover {
    min-height: 50px;
    border: 2px solid lightgray;
    position: absolute;
    top: 46px;
    left: 0;
    right: 0;
    background: #fff;
    border-radius: 3px;
    text-align: center;
}
.popover input {
    width: 95%;
    margin-top: 5px;
    height: 40px;
    font-size: 16px;
    border-radius: 3px;
    border: 1px solid lightgray;
    padding-left: 8px;
}
.options {
    max-height: 150px;
    overflow-y: scroll;
    margin-top: 5px;
}
.options ul {
    list-style-type: none;
    text-align: left;
    padding-left: 0;
}
.options ul li {
    border-bottom: 1px solid lightgray;
    padding: 10px;
    cursor: pointer;
    background: #f1f1f1;
}
.options ul li:first-child {
    border-top: 2px solid #d6d6d6;
}
.options ul li:not(.selected):hover {
    background: #8c8c8c;
    color: #fff;
}
.options ul li.selected {
    background: #58bd4c;
    color: #fff;
    font-weight: 600;
}
</style>